import { Number } from "./number";

export const BigNumberChart = ({ title, value }) => {
  return (
    <div className="chart-data-section col-xl-3 col-md-6 mb-4 m-3">
      <div className="card border-left-primary shadow h-100 py-2 card-hover">
        <div className="card-body">
          <div className="row no-gutters align-items-center">
            <div className="col mr-2">
              <div className="text-xs font-weight-bold text-primary mb-1">
                {title}
              </div>
              <div className="h5 mb-0 font-weight-bold text-gray-800"><Number count={parseInt(value)} /></div>
            </div>
            <div className="col-auto">
              <i className="fas fa-calendar fa-2x text-gray-300"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}