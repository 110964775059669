import {
    CDropdownItem,
  } from '@coreui/react'
  import { useKeycloak } from '@react-keycloak/web'

export default function Header() {
    const { keycloak, initialized } = useKeycloak()

    const handleLogout = () => {
        keycloak.logout()
    }
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <a className="navbar-brand" href="#"><img src="./proworld-logo.png" /></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
                <div className="container-fluid justify-content-end">
                    <button className='btn btn-primary' style={{ cursor: "pointer" }} onClick={handleLogout}>
                        Logout
                    </button>
                </div>
            </nav>
        </div>
    )
}