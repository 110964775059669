import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './keycloak'
import store from "./store";
import { Provider } from "react-redux";
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from "react-error-boundary";
import RedirectToLogin from './views/RedirectToLogin';


import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

const tokenLogger = ({ token }) => {
  localStorage.setItem("token", token);
};

const KeycloakTokenHandler = ({ children }) => {
  const [needsLogin, setNeedsLogin] = useState(false);

  useEffect(() => {
    keycloak.onTokenExpired = () => {
      keycloak.updateToken(30).then((refreshed) => {
        if (refreshed) {
          console.log('Token refreshed');
        } else {
          console.log('Token not refreshed, user needs to re-login');
          setNeedsLogin(true);
        }
      }).catch(() => {
        console.log('Failed to refresh token');
        setNeedsLogin(true);
      });
    };
  }, []);

  if (needsLogin) {
    return <RedirectToLogin />;
  }

  return children;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary fallback={<RedirectToLogin />}>
  <BrowserRouter basename="/">

    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "login-required",
      }}
      onTokens={tokenLogger}
    >
      <Provider store={store}>
        <App />
      </Provider>
      <KeycloakTokenHandler />
    </ReactKeycloakProvider>
    </BrowserRouter>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
