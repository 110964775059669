import './App.css';
import React, { Component, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import Dashboard from './components/dashboard/dashboard';
import { useKeycloak } from '@react-keycloak/web'
import Header from "./components/header/header";


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
function App() {
  // const { keycloak, initialized } = useKeycloak();
  return (
    <>
      <div className="App">
      <Header />
        <Suspense fallback={loading}>
          <Routes>
            <Route path="*" name="Dashboard" element={<Dashboard />}>
            </Route>
          </Routes>
        </Suspense>
      </div>
    </>
  );
}

export default App;
