import { useSpring, animated } from 'react-spring'
export const Number = ({ count }) => {
    const numberFormat = (number) => {
        return new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(
            number,
        )
    }
    const { number } = useSpring({
        from: { number: 0 },
        number: count,
        delay: 200,
        config: { mass: 1, tension: 200, friction: 100 }
    })
    return <animated.div>
        {/* Format animated number without decimals */}
        {number.to((n) => numberFormat(n))}
    </animated.div>
}