import React, { useState } from 'react';
import { BarChart, Bar, LabelList, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Chart = ({ data }) => {
    const [clickedData, setClickedData] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [hoverIndex, setHoverIndex] = useState(null);
    const [selectedDate, setSelectedDate] = useState();

    // Initialize an object to store summed counts by publisher and event type
    const summedCounts = {};

    // Process each object in the data array
    data.forEach(item => {
        let { publisher, details } = item;
        publisher = publisher.replace(/^(www\.)?/, "").replace(/\.\w+$/, "");
        // Iterate over details to sum counts by event_mode and publisher
        details.forEach(detail => {
            const { event_mode, count } = detail;

            if (!summedCounts[publisher]) {
                summedCounts[publisher] = {};
            }
            if (!summedCounts[publisher][event_mode]) {
                summedCounts[publisher][event_mode] = 0;
            }
            summedCounts[publisher][event_mode] += count;
        });
    });

    // Prepare data for Recharts BarChart
    const chartData = Object.keys(summedCounts).map(publisher => {
        const entry = { publisher };
        Object.keys(summedCounts[publisher]).forEach(eventType => {
            entry[eventType] = summedCounts[publisher][eventType];
        });
        return entry;
    });

    // Extract unique event types
    const eventTypes = [...new Set(data.flatMap(item => item.details.map(detail => detail.event_mode)))];

    const handleClick = (data, index) => {
        if (index === activeIndex) {
            setActiveIndex(null);
            setClickedData(null);
        } else {
            setActiveIndex(index);
            setSelectedDate(data.publisher); // Assuming publisher as the selected data here
            const clickedEvent = Object.keys(data).filter(key => key !== 'publisher')[index];
            setClickedData({
                publisher: data.publisher,
                event_mode: clickedEvent,
                count: data[clickedEvent],
            });
        }
    };

    const CustomXAxisTick = ({ x, y, payload }) => {
        const maxLength = 20; // Maximum length before truncating
        const displayText = payload.value.length > maxLength
            ? `${payload.value.substring(0, maxLength)}...`
            : payload.value;
    
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} textAnchor="middle" fill="#666">
                    {displayText}
                </text>
            </g>
        );
    };

    const keys = Array.from(new Set(chartData.flatMap(Object.keys))).filter(key => key !== 'publisher');
    const colors =["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#e6d800", "#beb9db", "#fdcce5", "#8bd3c7"];

    return (
        <div>
            <ResponsiveContainer width="100%" height={500}>
                <BarChart
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                    onClick={(data, index) => handleClick(data, index)}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="publisher" tick={<CustomXAxisTick />}/>
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {keys.map((key, index) => {
                    return (
                        <Bar
                            index={index}
                            key={key}
                            dataKey={key}
                            stackId="a"
                            fill={colors[index % colors.length]}
                        >
                            <LabelList dataKey={key} position="top"  content={({value}) => value} />
                        </Bar>
                    );
                })}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default Chart;
