import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import './custom.css'

const PublisherDataTable = ({ data }) => {
    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(0);

    // Group data by publisher and event_mode
    const groupedData = groupDataBypublisherAndEventType(data);

    // Extract unique publishers and event types
    const publishers = Object.keys(groupedData).sort();
    const eventTypes = [...new Set(data.flatMap(item => item.details.map(detail => detail.event_mode)))];

    // Calculate total number of pages
    const totalPages = Math.ceil(publishers.length / itemsPerPage);

    // Slice data to display only items for the current page
    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentPagepublishers = publishers.slice(startIndex, endIndex);

    // Function to handle pagination navigation
    const goToPage = (pageIndex) => {
        setCurrentPage(pageIndex);
    };

    return (
        <div className="table-responsive">
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Publisher</th>
                        {eventTypes.map(eventType => (
                            <th key={eventType}>{eventType}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {currentPagepublishers.map(publisher => (
                        <tr key={publisher}>
                            <td>{publisher.replace(/^(www\.)?/, "").replace(/\.\w+$/, "")}</td>
                            {eventTypes.map(eventType => (
                                <td key={`${publisher}_${eventType}`}>
                                    {groupedData[publisher][eventType] || 0}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
            {/* Pagination controls */}
            <div className="d-flex justify-content-center align-items-center">
                <Button variant="outline-primary" onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 0}>
                    Previous
                </Button>
                <span className="mx-3">{`Page ${currentPage + 1} of ${totalPages}`}</span>
                <Button variant="outline-primary" onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages - 1}>
                    Next
                </Button>
            </div>
        </div>
    );
};

// Group data by publisher and event_mode
const groupDataBypublisherAndEventType = (data) => {
    const groupedData = {};
    data.forEach(entry => {
        const { publisher, details } = entry;
        details.forEach(detail => {
            const { event_mode, count } = detail;
            if (!groupedData[publisher]) {
                groupedData[publisher] = {};
            }
            if (!groupedData[publisher][event_mode]) {
                groupedData[publisher][event_mode] = 0;
            }
            groupedData[publisher][event_mode] += count;
        });
    });
    return groupedData;
};

export default PublisherDataTable;
