import React, { useState } from 'react';
import Table from 'react-bootstrap/Table';
import { Button } from 'react-bootstrap'; // Import Bootstrap Button component


export const TableComp = ({ data }) => {
  const itemsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(0);
  // Calculate total number of pages
  //const totalPages = Math.ceil(data?.length / itemsPerPage);

  // Slice data to display only items for the current page
  // const startIndex = currentPage * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  //const currentPageData = data?.slice(startIndex, endIndex);

  // Initialize an object to store summed counts by date and event type
  const summedCounts = {};

  // Process each object in the current page of data
  data?.forEach(item => {
    const { event_mode, details } = item;

    // Iterate over details to sum counts by created_at and event_mode
    details.forEach(detail => {
      const { created_at, count } = detail;

      if (!summedCounts[created_at]) {
        summedCounts[created_at] = {};
      }
      if (!summedCounts[created_at][event_mode]) {
        summedCounts[created_at][event_mode] = 0;
      }
      summedCounts[created_at][event_mode] += count;
    });
  });

  console.log( "Date with data : " + JSON.stringify(summedCounts));
  //const currentPageData = summedCounts?.slice(startIndex, endIndex);

  // Extract unique dates
  const dates = Object.keys(summedCounts);
  const totalPages = Math.ceil(dates?.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageData = dates?.slice(startIndex, endIndex);

  // Extract unique event types
  const eventTypes = [...new Set(data?.flatMap(item => item.details.map(detail => detail?.event_mode)))];

  // Function to handle pagination navigation
  const goToPage = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  return (
    <div className="table-responsive">
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Created At</th>
            {eventTypes.map(eventType => (
              <th key={eventType}>{eventType}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {currentPageData.map(date => (
            <tr key={date}>
              <td>{date}</td>
              {eventTypes.map(eventType => (
                <td key={`${date}_${eventType}`}>
                  {summedCounts[date][eventType] || 0}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {/* Pagination controls */}
      <div className="d-flex justify-content-center align-items-center">
        <Button variant="outline-primary" onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 0}>
          Previous
        </Button>
        <span className="mx-3">{`Page ${currentPage + 1} of ${totalPages}`}</span>
        <Button variant="outline-primary" onClick={() => goToPage(currentPage + 1)} disabled={currentPage === totalPages - 1}>
          Next
        </Button>
      </div>
    </div>
  );
};