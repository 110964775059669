import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { format, startOfMonth } from 'date-fns';

const DateRangeComp = forwardRef(({ dateRange }, ref) => {
  const getCurrentMonthStartDate = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  };

  const [range, setRange] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const [open, setOpen] = useState(false);
  const refOne = useRef(null);

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true);
    document.addEventListener("click", hideOnClickOutside, true);
  }, []);

  useEffect(() => {
    if (typeof dateRange === 'function') {
      dateRange(range);
    }
  }, [range]);

  const hideOnEscape = (e) => {
    if (e.key === "Escape") {
      setOpen(false);
    }
  };

  const hideOnClickOutside = (e) => {
    if (refOne.current && !refOne.current.contains(e.target)) {
      setOpen(false);
    }
  };

  const changeDate = (item) => {
    if (typeof dateRange === 'function') {
      dateRange([item.selection]);
    }
    setRange([item.selection]);
  };

  const resetDate = () => {
    const newRange = {
      startDate: startOfMonth(new Date()),
      endDate: new Date(),
      key: 'selection'
    };
    setRange([newRange]);
    if (typeof dateRange === 'function') {
      dateRange([newRange]);
    }
  };

  useImperativeHandle(ref, () => ({
    resetDate
  }));

  return (
    <div className="calendarWrap" id="chart">
      <div className="input-group">
        <input
          type="text"
          value={`${format(range[0].startDate, "dd/MM/yyyy")} to ${format(range[0].endDate, "dd/MM/yyyy")}`}
          readOnly
          className="form-control"
          onClick={() => setOpen(open => !open)}
        />
        <div className="input-group-append flex" onClick={() => setOpen(open => !open)}>
          <span className="input-group-text" style={{ cursor: 'pointer', backgroundColor:'#fff' }}>
            <FontAwesomeIcon icon={faCalendarAlt} />
          </span>
        </div>
      </div>

      <div ref={refOne}>
        {open &&
          <DateRange
            onChange={item => changeDate(item)}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="horizontal"
            className="calendarElement"
            maxDate={new Date()}
          />
        }
      </div>
    </div>
  );
});

export default DateRangeComp;
