import './filter.css'
import "../../App.css"
import { useEffect, useRef, useState } from 'react'

import { format, startOfMonth } from 'date-fns';
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import DateRangeComp from '../shared/dateRange'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MultiSelect } from 'primereact/multiselect';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/saga-blue/theme.css";



export default function Filter({ role, publisherList, filters }) {
    const [filterData, setFilterData] = useState();
    const [clearFilter, setClearFilter] = useState(false);
    const [displayFilter, setDisplayFilter] = useState(false);
    const [selectedPublisher, setSelectedPublisher] = useState(null);
    const dateRangeCompRef = useRef();
    const applyFilters = () => {
        if (filterData) {
            setClearFilter(true);
            filters(filterData);
        }
    }
    const clearFilters = () => {
        setClearFilter(false);
        setDisplayFilter(false);
        setSelectedPublisher(null);
        setFilterData(null)
        filters({
            created_at: {
                startDate: `${format(startOfMonth(new Date()), "yyyy-MM-dd 00:00:00.000")}`,
                endDate: `${format(new Date(), "yyyy-MM-dd 23:59:59.999")}`
            },
        });
        dateRangeCompRef.current.resetDate()
    }
    const dateFilter = (dateRange) => {
        const created_at = {
            startDate: format(dateRange[0].startDate, "yyyy-MM-dd 00:00:00.000"),
            endDate: format(dateRange[0].endDate, "yyyy-MM-dd 23:59:59.999")
        }
        setFilterData(prevFilterData => ({
            ...prevFilterData,
            created_at // Update or add new key-value pair
        }))
        setDisplayFilter(true);
    }
    const publisherFilter = (item) => {
        setDisplayFilter(true);
        setSelectedPublisher(item.target.value)
        if(item.target.value !== "Select Publisher Name"){
            setFilterData(prevFilterData => ({
                ...prevFilterData,
                publishers: [item.target.value] // Update or add new key-value pair
            }))
        }
        else{
            setFilterData(prevFilterData => {
                const { publishers, ...rest } = prevFilterData; // Destructure to exclude publisherName
                return rest; // Return the new object without publisherName
            });
        }
    }

    useEffect(()=>{
        if(!role && publisherList.length>0){
            setSelectedPublisher(publisherList[0].domain);
        }
    },[publisherList])

    useEffect(() => {
        setDisplayFilter(true);
    }, [filters])
    return (
        <div className="flex filter-section align-items-center">
            <div className='flex  align-items-center' style={{ width: "35%" }}>
            <label style={{ marginRight: "10px", width:'50%', textAlign:'end' }}>  Filter By Date  </label>
               <DateRangeComp dateRange={dateFilter} ref={dateRangeCompRef} />
            </div>
            {(role || publisherList) &&
                <div className='flex align-items-center' style={{ marginLeft: '20px', width: "30%" }}>
                    <label style={{ marginRight: "25px", width:'50%', textAlign:'end' }}>Filter by Publisher</label>
                    {/* <MultiSelect style={{width:"70%"}} options={publisherList} optionLabel="publisher" onChange={(item) => publisherFilter(item)} value={selectedPublisher || ''}
                        placeholder="Select Publishers" maxSelectedLabels={3} className="w-full md:w-20rem" display='chip' />                  */}
                    <select className="form-select" aria-label="Publisher name" onChange={(item) => publisherFilter(item)} value={selectedPublisher || ''}>
                        <option value={null}>Select Publisher Name</option>
                        {publisherList.map((data, index) => (
                            <option key={index} value={data.domain}>{data.publisher}</option>
                        ))}
                    </select>
                </div>}
            <div className='filter' style={{ marginLeft: "10px" }}>
                {displayFilter && <button className="btn btn-primary m-3" onClick={() => applyFilters()}><FontAwesomeIcon icon={faPlus} /> Apply Filter</button>}
                {clearFilter && <button className="btn btn-danger m-3" onClick={() => clearFilters()}><FontAwesomeIcon icon={faTimes} /> Clear Filter</button>}
            </div>
        </div>
    )
}