import React, { useState } from 'react';
import { BarChart, Bar, LabelList, Line, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export const Chart = ({ data }) => {
    const [clickedData, setClickedData] = useState(null);
    const [activeIndex, setActiveIndex] = useState(null);
    const [hoverIndex, setHoverIndex] = useState(null);
    const [selectedDate, setSelectedDate] = useState();
    // Initialize an object to store summed counts by date and event type
    const summedCounts = {};

    // Process each object in the data array
    data.forEach(item => {
        const { event_mode, details } = item;

        // Iterate over details to sum counts by created_at and event_mode
        details.forEach(detail => {
            const { created_at, count } = detail;

            if (!summedCounts[created_at]) {
                summedCounts[created_at] = {};
            }
            if (!summedCounts[created_at][event_mode]) {
                summedCounts[created_at][event_mode] = 0;
            }
            summedCounts[created_at][event_mode] += count;
        });
    });

    // Prepare data for Recharts LineChart
    const chartData = Object.keys(summedCounts).map(date => {
        const entry = { date };
        Object.keys(summedCounts[date]).forEach(eventType => {
            entry[eventType] = summedCounts[date][eventType];
        });
        return entry;
    });

    // Extract unique event types
    const eventTypes = [...new Set(data.flatMap(item => item.details.map(detail => detail.event_mode)))];

    // const handleClick = (data, index) => {
    //     extractRelevantData(chartData[data.activeTooltipIndex]);
    //     // setActiveIndex(index);
    // };

    const handleClick = (data, index) => {
        if (index === activeIndex) {
            setActiveIndex(null);
            setClickedData(null);
        } else {
            setActiveIndex(index);
            setSelectedDate(data.date);
            const clickedEvent = Object.keys(data).filter(key => key !== 'date')[index];
            setClickedData({
                date: data.date,
                event_mode: clickedEvent,
                count: data[clickedEvent],
            });
        }
    };
    const keys = Array.from(new Set(chartData.flatMap(Object.keys))).filter(key => key !== 'date');
    const colors = ["#fd7f6f", "#7eb0d5", "#b2e061", "#bd7ebe", "#ffb55a", "#e6d800", "#beb9db", "#fdcce5", "#8bd3c7"];
    return (
        <div>
            <ResponsiveContainer width="100%" height={500}>
                <BarChart
                    data={chartData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    onClick={(data, index) => handleClick(data, index)}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {keys.map((key, index) => {
                        return (
                            <Bar
                                index={index}
                                key={key}
                                dataKey={key}
                                stackId="a"
                                fill={colors[index % colors.length]}
                            >
                                <LabelList dataKey={key} position="top" content={({ value }) => value} />
                            </Bar>
                        );
                    })}
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};
